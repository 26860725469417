import requestExodus from 'lib/api/instances/exodus';
import requestTradingConfig from 'config/requestTrading';
import {
  API_TRADING_URL,
  API_SECURITIES_GENERAL_URL,
  API_TRADING_URL_V2,
} from 'constants/api';
import { isRedirectToNewCore, generateUiRef } from 'features/securities/utils';
import { expiryId } from 'global/Securities/constants/transactionForm';
import {
  TransactionHistoryParams,
  TransactionHistoryResponseBody,
} from 'global/Securities/types/api/history';
import { Expiry } from 'global/Securities/types/transaction';
import { GetSubAccountDayTradeStatusResponse } from 'global/Securities/types/api/termsAndConditions';
import { StockTradableResponse } from 'global/Securities/types/api/stockTradable';
import { BulkTradeAmendPayload } from 'global/Securities/types/trading';
import { APIResponse } from '../../../@types/api-response';

const requestTrading = requestTradingConfig(API_TRADING_URL, false, false);
const requestTradingV2 = requestTradingConfig(API_TRADING_URL_V2, false, false);
const requestSocial = requestExodus.noAlert;

const getPortfolio = (): Promise<APIResponse> => {
  if (isRedirectToNewCore()) {
    return requestTradingV2.get(`${API_TRADING_URL_V2}/portfolio/v2/list`);
  }
  return requestTrading.get(`${API_TRADING_URL}/portfolio`);
};

const getConfigFormula = (): Promise<APIResponse> => {
  if (isRedirectToNewCore()) {
    return requestTradingV2.get(`${API_TRADING_URL_V2}/formula/v2`);
  }
  return requestTrading.get(`${API_TRADING_URL}/config/formula`);
};

const getTradingConfig = (): Promise<APIResponse> =>
  requestTrading.get(`${API_TRADING_URL}/get`);

const unlinkTradingAccount = (brokerKey: string): Promise<APIResponse> =>
  requestTrading.post(`${API_TRADING_URL}/unlink`, { broker: brokerKey });

const getOrderList = (gtc: number, stock_code = ''): Promise<APIResponse> => {
  if (isRedirectToNewCore()) {
    if (stock_code) {
      return requestTradingV2.get(`${API_TRADING_URL_V2}/order/v2/list`, {
        params: { 'filter_criteria.stock_code': stock_code },
      });
    }
    return requestTradingV2.get(`${API_TRADING_URL_V2}/order/v2/list`);
  }
  return requestTrading.get(`${API_TRADING_URL}/order/list?gtc=${gtc}`);
};

const getExerciseList = () => {
  if (isRedirectToNewCore()) {
    return requestTradingV2.get(`${API_TRADING_URL_V2}/exercise`);
  }
  return requestTrading.get(`${API_TRADING_URL}/exercise/list`);
};

const getExercise = (stockCode) => {
  if (isRedirectToNewCore()) {
    return requestTradingV2.get(
      `${API_TRADING_URL_V2}/exercise/exercisable?stockCode=${stockCode}`,
    );
  }
  return requestTrading.get(
    `${API_TRADING_URL}/exercise/exerciseable/v2?stockCode=${stockCode}`,
  );
};

const checkRightTradeable = () => {
  if (isRedirectToNewCore()) {
    return requestTradingV2.get(`${API_TRADING_URL_V2}/exercise/tradable`);
  }
  return requestTrading.get(`${API_TRADING_URL}/exercise/tradeable`);
};

const checkStockTradable = (
  symbol: string,
): Promise<APIResponse<StockTradableResponse[]>> =>
  requestTradingV2.get(
    `${API_TRADING_URL_V2}/stock/tradable?stock_codes=${symbol}`,
  );

const getPortfolioDetail = (symbol: string): Promise<APIResponse> => {
  if (isRedirectToNewCore()) {
    return requestTradingV2.get(
      `${API_TRADING_URL_V2}/portfolio/v2/detail?stock_code=${symbol}`,
    );
  }
  return requestTrading.get(
    `${API_TRADING_URL}/portfolio/detail?symbol=${symbol}`,
  );
};

const getCashOnHand = () => {
  if (isRedirectToNewCore()) {
    return requestTradingV2.get(`${API_TRADING_URL_V2}/balance/cash`);
  }
  return requestTrading.get(`${API_TRADING_URL}/account/cashonhand`);
};

const getHistory = async (
  params: TransactionHistoryParams,
): Promise<APIResponse<TransactionHistoryResponseBody>> => {
  if (isRedirectToNewCore()) {
    return requestTradingV2.get(`${API_TRADING_URL_V2}/history`, {
      params,
    });
  }
  return requestTrading.post(`${API_TRADING_URL}/order/history/v4`, params);
};

/**
 * @deprecated we should migrate to the /history or `getHistory` API
 */
const getTransactionHistory = async ({
  stockCode,
  period,
  page = '1',
  limit = '500',
}: TransactionHistoryParams): Promise<
  APIResponse<TransactionHistoryResponseBody>
> => {
  if (isRedirectToNewCore()) {
    const queries = new URLSearchParams({ stockCode, period, page, limit });
    return requestTradingV2(`${API_TRADING_URL_V2}/history/v3?${queries}`);
  }

  return requestTrading.post(`${API_TRADING_URL}/order/history/v3`, {
    stockCode,
    period,
    page,
    limit,
  });
};

const tradeBuy = ({
  orderkey,
  symbol,
  price,
  shares,
  boardtype = 'RG', // RG = Saham. TN = Right
  gtc,
  split_order,
  platform_order_type,
}) => {
  if (isRedirectToNewCore()) {
    const body = {
      ui_ref: generateUiRef(),
      symbol,
      price,
      shares,
      board_type: boardtype,
      is_gtc: gtc === Expiry.GoodTillCancelled,
      time_in_force: '0',
      split_order,
      platform_order_type,
    };
    return requestTradingV2.post(`${API_TRADING_URL_V2}/order/v2/buy`, body);
  }
  return requestTrading.post(`${API_TRADING_URL}/order/buy`, {
    orderkey,
    symbol,
    price,
    shares,
    boardtype,
    gtc: expiryId[gtc],
  });
};

const dayTradeBuy = ({ symbol, price, shares, multiplier }) =>
  requestTradingV2.post(`${API_TRADING_URL_V2}/order/day-trade/v1/buy`, {
    ui_ref: generateUiRef(),
    symbol,
    price,
    shares,
    multiplier,
  });

const tradeSell = ({
  orderkey,
  symbol,
  price,
  shares,
  boardtype = 'RG', // RG = Saham. TN = Right
  gtc = Expiry.GoodForDay,
  split_order,
  platform_order_type,
}) => {
  if (isRedirectToNewCore()) {
    const body = {
      ui_ref: generateUiRef(),
      symbol,
      price,
      shares,
      board_type: boardtype,
      is_gtc: gtc === Expiry.GoodTillCancelled,
      time_in_force: '0',
      split_order,
      platform_order_type,
    };
    return requestTradingV2.post(`${API_TRADING_URL_V2}/order/v2/sell`, body);
  }
  return requestTrading.post(`${API_TRADING_URL}/order/sell`, {
    orderkey,
    symbol,
    price,
    shares,
    boardtype,
    gtc: expiryId[gtc],
  });
};

const dayTradeSell = ({ symbol, price, shares }) =>
  requestTradingV2.post(`${API_TRADING_URL_V2}/order/day-trade/v1/sell`, {
    ui_ref: generateUiRef(),
    symbol,
    price,
    shares,
  });

/**
 * @deprecated use `bulkTradeAmend` instead
 */
const tradeAmend = ({
  orderkey,
  symbol,
  price,
  shares,
  orderid,
  boardtype = 'RG', // RG = Saham. TN = Right
}) => {
  if (isRedirectToNewCore()) {
    const body = {
      order_id: orderid,
      ui_ref: generateUiRef(),
      symbol,
      price,
      shares,
      board_type: boardtype,
    };
    return requestTradingV2.post(`${API_TRADING_URL_V2}/order/v2/amend`, body);
  }

  return requestTrading.post(`${API_TRADING_URL}/order/amend`, {
    orderkey,
    symbol,
    price,
    shares,
    orderid,
  });
};

const bulkTradeAmend = (orders: BulkTradeAmendPayload) =>
  requestTradingV2.post(`${API_TRADING_URL_V2}/order/v2/amend/bulk`, {
    ui_ref: generateUiRef(),
    amend_request: orders,
  });

const dayTradeAmend = ({ orderid, price, shares, multiplier }) =>
  requestTradingV2.post(`${API_TRADING_URL_V2}/order/day-trade/v1/amend`, {
    ui_ref: generateUiRef(),
    multiplier,
    price,
    shares,
    order_id: orderid,
  });

const tradeCancel = (data) => {
  if (isRedirectToNewCore()) {
    const body = { order_id: data.orderid, ui_ref: generateUiRef() };
    return requestTradingV2.post(`${API_TRADING_URL_V2}/order/v2/cancel`, body);
  }
  return requestTrading.post(`${API_TRADING_URL}/order/cancel`, data);
};

const dayTradeCancel = (orderKey: string) => {
  const data = {
    ui_ref: generateUiRef(),
    order_id: orderKey,
  };

  return requestTradingV2.post(
    `${API_TRADING_URL_V2}/order/day-trade/v1/cancel`,
    data,
  );
};

const postRequestExercise = ({ orderKey, stockCode, shares }) => {
  if (isRedirectToNewCore()) {
    return requestTradingV2.post(`${API_TRADING_URL_V2}/exercise`, {
      stock_code: stockCode,
      shares,
    });
  }

  return requestTrading.post(`${API_TRADING_URL}/exercise/request`, {
    stock_code: stockCode,
    shares,
    order_key: orderKey,
  });
};

const logout = () => {
  if (isRedirectToNewCore()) {
    return requestTradingV2.post(`${API_TRADING_URL_V2}/auth/logout`);
  }
  return requestTrading.post(`${API_TRADING_URL}/auth/logout`);
};

const getTradingProfile = (): Promise<APIResponse> => {
  if (isRedirectToNewCore()) {
    return requestTradingV2.get(`${API_TRADING_URL_V2}/account`);
  }
  return requestTrading.get(`${API_TRADING_URL}/account`);
};

const getTradingProfilePersonal = async (): Promise<APIResponse> =>
  requestSocial.get(`${API_SECURITIES_GENERAL_URL}/account/personal`);

/**
 * Validate PIN
 * @param {string} pin - user input
 * @param {string} type - string
 * @return {APIResponse}
 */
const checkPin = (pin, type): Promise<APIResponse> => {
  if (isRedirectToNewCore()) {
    return requestTradingV2.post(`${API_TRADING_URL_V2}/auth/pin/validate`, {
      pin,
      purpose: type,
    });
  }
  return requestTrading.post(`${API_TRADING_URL}/auth/pin/validate`, {
    pin,
    type,
  });
};

/**
 * Change PIN
 * @param {string} pin - user input
 * @param {string} type - string
 * @return {APIResponse}
 */
const changePin = (old_pin, new_pin, token): Promise<APIResponse> => {
  if (isRedirectToNewCore()) {
    return requestTradingV2.post(`${API_TRADING_URL_V2}/auth/pin/change`, {
      old_pin,
      new_pin,
      token,
    });
  }

  return requestTrading.post(`${API_TRADING_URL}/auth/changepin`, {
    old_pin,
    new_pin,
    token,
  });
};

const checkSubAccountDayTrade = (
  type: string,
): Promise<APIResponse<GetSubAccountDayTradeStatusResponse>> =>
  requestTradingV2.get(
    `${API_TRADING_URL_V2}/account/sub-account/detail?type=${type}`,
  );

const deleteTrailingStop = (orderId: string): Promise<APIResponse> =>
  requestTradingV2.delete(`/smart-order/trailing-stop/v1/order/${orderId}`);

const newChangePin = (pin: string, token: string): Promise<APIResponse> =>
  requestTradingV2.post(`${API_TRADING_URL_V2}/auth/v2/pin/change/new`, {
    pin,
    token,
  });

const confirmChangePin = (pin: string, token: string): Promise<APIResponse> =>
  requestTradingV2.post(`${API_TRADING_URL_V2}/auth/v2/pin/change/confirm`, {
    pin,
    token,
  });

const validateIdentity = (identity: string): Promise<APIResponse> =>
  requestTradingV2.post(`${API_TRADING_URL_V2}/auth/v2/pin/reset/validate`, {
    identity,
  });

const newResetPin = (pin: string, token: string): Promise<APIResponse> =>
  requestTradingV2.post(`${API_TRADING_URL_V2}/auth/v2/pin/reset/new`, {
    pin,
    token,
  });

const confirmResetPin = (pin: string, token: string): Promise<APIResponse> =>
  requestTradingV2.post(`${API_TRADING_URL_V2}/auth/v2/pin/reset/confirm`, {
    pin,
    token,
  });

const sendChangeOTP = (token: string, channel: string): Promise<APIResponse> =>
  requestTradingV2.post(`${API_TRADING_URL_V2}/auth/v2/pin/change/otp/send`, {
    token,
    channel,
  });

const verifyChangeOTP = (token: string, otp: string): Promise<APIResponse> =>
  requestTradingV2.post(`${API_TRADING_URL_V2}/auth/v2/pin/change/otp/verify`, {
    token,
    otp,
  });

const sendResetPinOTP = (token: string, channel: string): Promise<APIResponse> =>
  requestTradingV2.post(`${API_TRADING_URL_V2}/auth/v2/pin/reset/otp/send`, {
    token,
    channel,
  });

const verifyResetPinOTP = (token: string, otp: string): Promise<APIResponse> =>
  requestTradingV2.post(`${API_TRADING_URL_V2}/auth/v2/pin/reset/otp/verify`, {
    token,
    otp,
  });

export default {
  getConfigFormula,
  getPortfolio,
  getOrderList,
  getExerciseList,

  // trade
  getExercise,
  getPortfolioDetail,
  getCashOnHand,
  tradeBuy,
  tradeSell,
  tradeAmend,
  bulkTradeAmend,
  tradeCancel,
  postRequestExercise,

  // day trade
  dayTradeBuy,
  dayTradeSell,
  dayTradeAmend,
  dayTradeCancel,

  getTradingConfig,
  unlinkTradingAccount,
  logout,
  getHistory,
  getTransactionHistory,
  getTradingProfile,
  getTradingProfilePersonal,
  checkPin,
  changePin,

  newChangePin,
  confirmChangePin,
  sendChangeOTP,
  verifyChangeOTP,
  validateIdentity,
  newResetPin,
  confirmResetPin,
  sendResetPinOTP,
  verifyResetPinOTP,

  checkRightTradeable,
  checkStockTradable,

  checkSubAccountDayTrade,

  deleteTrailingStop,
};

import create from 'zustand';

export enum ModalNames {
  PORTFOLIO_ONBOARDING = 'PORTFOLIO_ONBOARDING',
  PORTFOLIO_CREATE = 'PORTFOLIO_CREATE',
  PORTFOLIO_CREATE_BLOCKED = 'PORTFOLIO_CREATE_BLOCKED',
  PORTFOLIO_TNC = 'PORTFOLIO_TNC',
  PORTFOLIO_SUCCESS = 'PORTFOLIO_SUCCESS',
  PORTFOLIO_RENAME = 'PORTFOLIO_RENAME',

  TRANSFER_SELECT = 'TRASNFER_SELECT',

  TRANSFER_CASH = 'TRANSFER_CASH',
  TRANSFER_CASH_CONFIRM = 'TRANSFER_CASH_CONFIRM',
  TRANSFER_CASH_SUCCESS = 'TRANSFER_CASH_SUCCESS',
  TRANSFER_CASH_BALANCE_REVIEW = 'TRANSFER_CASH_BALANCE_REVIEW',

  TRANSFER_STOCK = 'TRANSFER_STOCK',
  TRANSFER_STOCK_CONFIRM = 'TRANSFER',
  TRANSFER_STOCK_SUCCESS = 'TRANSFER_STOCK_SUCCESS',
}

interface ModalState {
  activeModal: ModalNames | null;
  openModal: (modalName: ModalNames) => void;
  closeModal: () => void;
}

const useModalStore = create<ModalState>((set) => ({
  activeModal: null,
  openModal: (modalName) => set({ activeModal: modalName }),
  closeModal: () => set({ activeModal: null }),
}));

export default useModalStore;
